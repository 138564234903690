import React, { Component } from 'react';
import ReactGA from 'react-ga';
import NotificationAlert from 'react-notification-alert';
import { instanceOf } from 'prop-types';
import { CookiesProvider, withCookies, Cookies } from 'react-cookie';
import './App.css';

import Routes from './routes';

class App extends Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;
    this.state = {
      user: cookies.get('user'),
      cakes: [],
      maxDateCake: '2019-01-01T00:00:00.005Z'
    };
    
    require('dotenv').config();

    ReactGA.initialize('UA-149936865-1');
    ReactGA.pageview('/');
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.notifyRef = React.createRef();

    this.setCakes = this.setCakes.bind(this);
    this.removeCake = this.removeCake.bind(this);
    this.setCookie = this.setCookie.bind(this);
    this.removeCookie = this.removeCookie.bind(this);
    this.notify = this.notify.bind(this);
    this.dismissNotify = this.dismissNotify.bind(this);
  }

  setCakes(pCakes) {
    for (let i = 0; i < pCakes.length; i++) {
      this.setState({
        cakes: [pCakes[i]]
          .concat(this.state.cakes
            .filter(c => c._id !== pCakes[i]._id)),
        maxDateCake: pCakes[i].updatedAt
      });
    }
  }

  removeCake(pId) {
    this.setState({
      cakes: this.state.cakes.filter(c => c._id !== pId)
    });
  }

  setCookie(name, value) {
    const { cookies } = this.props;
    var expiryDate = (2 * 24 * 60 * 60); // 1 year in seconds

    cookies.set(name, value, { path: '/', maxAge: expiryDate });
    this.setState({ [name]: value });
  }

  removeCookie(name) {
    const { cookies } = this.props;

    cookies.remove(name, { path: '/' });
    this.setState({ [name]: null });
  }

  notify(tipo, msg, seconds, close) {
    var options = {
      place: 'tc',
      message: msg,
      type: tipo,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: seconds,
      closeButton: close === undefined
    }

    this.notifyRef.current.notificationAlert(options);
  }

  dismissNotify(nNumber, place, noAnimate) {
    this.notifyRef.current.onDismiss(nNumber, place, noAnimate);
  }

  render() {
    return (
      <CookiesProvider>
        <NotificationAlert ref={this.notifyRef} />
        <Routes
          state={this.state}
          removeCookie={this.removeCookie}
          setCookie={this.setCookie}
          setUser={this.setUser}
          notify={this.notify}
          dismissNotify={this.dismissNotify}
          setCakes={this.setCakes}
          removeCake={this.removeCake}
        />
      </CookiesProvider>
    );
  }
}

export default withCookies(App);
