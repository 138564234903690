import React, { useEffect, useState, useRef } from 'react';

import Menu from './Menu';
import TableCustos from './TableCustos';

// import './Custo.css';

import api from '../services/api';

const Custo = (p) => {

    const refCusto = useRef(null);

    const [user, setUser] = useState({
        _id: ''
    });
    const [custos, setCustos] = useState([]);
    const custoModel = ({
        descricao: '',
        valor: 0
    });
    const [custo, setCusto] = useState({
        descricao: '',
        valor: 0
    });
    const [updatingCusto, setUpdatingCusto] = useState(false);

    //atualiza dados do custo
    const modifyCusto = e => {
        let value = e.target.value;
        if (e.target.type === 'number') value = value.replace(',', '.');

        setCusto({
            ...custo,
            [e.target.name]: value,
        }
        );
    }

    //atualizar custo
    async function updateCusto(pCusto) {
        setCusto(pCusto);
        refCusto.current.focus();
        setUpdatingCusto(true);
    }

    //deletar custo
    async function delCusto(pCustos, callback) {

        for (let index = 0; index < pCustos.length; index++) {
            const pCusto = pCustos[index];

            const custoId = {
                headers: {
                    custoid: pCusto._id,
                }
            };

            await api.get('/deleteCusto', custoId);

            loadCustos(user._id);
            p.app.notify('warning', `Custo ${pCusto.descricao} deletado!`, 2);
        }

        if (updatingCusto) {
            cancelUpdating();
        }

        callback();
    }

    function cancelUpdating() {
        setUpdatingCusto(false);
        setCusto(custoModel);
    }

    //buscar custos pelo id do usuario
    async function loadCustos(pUserId) {
        const user_id = {
            headers: {
                user: pUserId,
            }
        };

        const response = await api.get('/listCusto', user_id);
        setCustos(response.data);
    }

    //cadastrar Custo
    async function handleSaveCusto(e) {
        e.preventDefault();

        custo.user_id = user._id;
        var path;

        if (custo.hasOwnProperty('_id')) {
            path = '/updateCusto';
        } else {
            path = '/createCusto';
        }

        const response = await api.post(path, {
          custo
        });

        if (response) {
            loadCustos(user._id);
            p.app.notify('success', 'Custo salvo com sucesso!', 2);
            cancelUpdating();
        } else {
            p.app.notify('danger', 'Custo não salvo!', 2);
        }

    }

    //carregar usuario
    useEffect(() => {

        if (p.app.state.user) {
            setUser(p.app.state.user);
        } else {
            p.history.push('/');
        };
    }, [p]);

    useEffect(() => {

        if (user._id !== '') loadCustos(user._id);

    }, [user._id]);

    return (
        <div>
            {(p.app.state.user)
                ? <Menu userId={p.app.state.user._id} removeCookie={p.app.removeCookie} history={p.history} />
                : null
            }

            <div className="main-container">

                <div className="container">

                    <div className="row">

                        <div className="col-md-8 mb-4">
                            <h4 name="formCusto" className="mb-1">Custos Fixos Mensais</h4>
                        </div>

                        {/* <!-- Adicionar nova categoria --> */}
                        <div className="col-md-12 mb-4">
                            <form
                                className="needs-validation"
                                noValidate=""
                                onSubmit={handleSaveCusto}
                            >
                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="descricao">Descrição</label>
                                        <input
                                            ref={refCusto}
                                            type="text"
                                            className="form-control"
                                            name="descricao"
                                            value={custo.descricao}
                                            onChange={modifyCusto}
                                            required={true}
                                        />
                                        <span
                                            hidden={updatingCusto === false}
                                            style={{
                                                width: '100%',
                                                marginTop: '.25rem',
                                                fontSize: '80%',
                                                color: '#3A1246'
                                            }}>
                                            Atualizando custo {custo.descricao}... &nbsp;&nbsp;
                                            <span
                                                onClick={cancelUpdating}
                                                style={{
                                                    color: '#3587dc',
                                                    cursor: 'pointer'
                                                }}>Cancelar</span>
                                        </span>
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="custo">Valor Mensal</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">R$</span>
                                            </div>
                                            <input
                                                type="number"
                                                pattern="[0-9]+([\,|\.][0-9]+)?"
                                                step="0.01"
                                                className="form-control"
                                                name="valor"
                                                value={custo.valor}
                                                onChange={modifyCusto}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md mb-3">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-lg btn-block btn-save-custo"
                                        >Salvar Custo</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/* <!-- Tabela de Custos --> */}
                        <TableCustos
                            rows={custos}
                            updateCusto={updateCusto}
                            delCusto={delCusto}
                        />


                    </div>

                </div>

                <footer className="my-5 pt-5 text-muted text-center text-small">
                    <p className="mb-1">© 2019 Lançando Cursos</p>
                </footer>

            </div>


        </div>
    );
}

export default Custo;