import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import Menu from './Menu';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import api from '../services/api';

const useStyles = makeStyles(theme => ({
    root: {
        width: 300,
    },
    margin: {
        height: theme.spacing(10),
    },
}));

const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 60,
        label: '60%',
    },
    {
        value: 100,
        label: '100%',
    },
    {
        value: 300,
        label: '300%',
    },
];

const User = (p) => {

    const classes = useStyles();
    const [user, setUser] = useState({});
    const [changePassword, setChangePassword] = useState({
        password: '',
        new_password: '',
        pass_confirmation: '',
        error: ''
    });

    function valuetext(value) {
        return `${value}%`;
    }

    //atualiza dados do usuário 
    const modifyUser = e => {
        let value = e.target.value;
        if (e.target.type === 'number') value = value.replace(',', '.');

        setUser({
            ...user,
            [e.target.name]: value,
        });
    }

    //atualizar senha
    const modifyPassword = e => {

        setChangePassword({
            ...changePassword,
            [e.target.name]: e.target.value,
        });
    }

    function modifyLucro(lucro) {

        setUser({
            ...user,
            lucro: lucro
        });
    }

    //carregar usuario
    useEffect(() => {

        if (p.app.state.user) {
            setUser(p.app.state.user);
        } else {
            p.history.push('/')
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleSaveUser(e) {
        e.preventDefault();

        const response = await api.post('/updateUser', {
            user
        });

        setUser(response.data);
        p.app.setCookie('user',response.data);
        p.app.notify('success', 'Usuário salvo com sucesso!', 2);

    }

    async function onPasswordChange(e) {
        e.preventDefault();

        const userHeaders = {
            user: {
                email: user.email,
                password: changePassword.password,
                newpass: changePassword.new_password
            }
        };

        await api.post('/updateUserPass', userHeaders).then((response) => {
            if (response.status === 200) {
                p.app.notify('success', 'Senha alterada com sucesso!', 2)
                setChangePassword({
                    password: '',
                    new_password: '',
                    pass_confirmation: '',
                    error: ''
                });
            } else {
                const error = new Error(response.error);
                throw error;
            }
        }).catch((err) => {
            setChangePassword({
                ...changePassword,
                error: err.response.data.error,
            });
        });
    }

    return (
        <div>
            <Menu userId={p.app.state.user._id} removeCookie={p.app.removeCookie} history={p.history} />

            <div className="main-container">

                <div className="container">

                    <div className="row">

                        {/* Dados do Usuário */}
                        <div className="col-md">

                            <div className="row">
                                <div className="col-md-8 mb-3">
                                    <h4 className="mb-1">Ficha do Usuário</h4>
                                    <small className="text-muted">
                                        Última Atualização: {Moment(user.updatedAt).format('DD/MM/YYYY HH:mm')}
                                    </small>
                                </div>
                            </div>

                            {(user._id) ?

                            (<form className="needs-validation" noValidate="" onSubmit={handleSaveUser}>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="nome">Nome</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nome"
                                            value={user.nome}
                                            onChange={modifyUser}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="nome">E-mail</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            readOnly={true}
                                            value={user.email}
                                            onChange={modifyUser}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="salario">Quanto gostaria de ganhar por mês?</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">R$</span>
                                            </div>
                                            <input
                                                type="number"
                                                pattern="[0-9]+([\,|\.][0-9]+)?"
                                                step="0.01"
                                                className="form-control"
                                                name="salario"
                                                value={user.salario}
                                                onChange={modifyUser}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="horasSemana">Trabalhando quantas horas por semana?</label>
                                        <input
                                            type="number"
                                            pattern="[0-9]+([\,|\.][0-9]+)?"
                                            step="0.01"
                                            className="form-control"
                                            name="horas_semana"
                                            value={user.horas_semana}
                                            onChange={modifyUser}
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="salarioHora">Seu valor por hora</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">R$</span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={parseFloat(user.salario / (user.horas_semana * 4)).toFixed(2)}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {(user._id)
                                        ? (<div className="col-md mb-3">
                                            <div className={classes.margin}>
                                                <Typography id="discrete-slider-always" gutterBottom>
                                                    Sua margem de lucro?
                                            </Typography>
                                                <Slider
                                                    getAriaValueText={valuetext}
                                                    aria-labelledby="discrete-slider-always"
                                                    step={1}
                                                    marks={marks}
                                                    valueLabelDisplay="on"
                                                    max={300}
                                                    value={user.lucro}
                                                    onChange={(e, val) => modifyLucro(val)}
                                                />
                                            </div>
                                        </div>)
                                        : null
                                    }
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="btn-save-user"></label>
                                        <button
                                            type="submit"
                                            id="btn-save-user"
                                            className="btn btn-primary btn-lg btn-block"
                                        >Salvar Usuário</button>
                                    </div>
                                </div>

                            </form>)

                            : null }

                        </div>

                    </div>

                    <hr></hr>

                    <div className="row">

                        {/* Dados do Usuário */}
                        <div className="col-md">

                            <div className="row">
                                <div className="col-md-8 mb-3">
                                    <h4 className="mb-1">Alterar Senha</h4>
                                </div>
                            </div>

                            <form className="needs-validation" noValidate="" onSubmit={onPasswordChange}>

                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="nome">Senha Atual</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            value={changePassword.password}
                                            onChange={modifyPassword}
                                            required={true}
                                        />
                                        <span style={{
                                            width: '100%',
                                            marginTop: '.25rem',
                                            fontSize: '80%',
                                            color: '#dc3545'
                                        }}>
                                            {changePassword.error}
                                        </span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="nome">Nova senha</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="new_password"
                                            value={changePassword.new_password}
                                            onChange={modifyPassword}
                                            autoComplete="new-password"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="nome">Confirme Nova senha</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="pass_confirmation"
                                            value={changePassword.pass_confirmation}
                                            onChange={modifyPassword}
                                            autoComplete="pass_confirmation"
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        {(changePassword.new_password !== '' && changePassword.pass_confirmation !== '')
                                            ? ((changePassword.new_password === changePassword.pass_confirmation)
                                                ? (<span style={{
                                                    width: '100%',
                                                    marginTop: '.25rem',
                                                    fontSize: '80%',
                                                    color: '#29a73e'
                                                }}>
                                                    As senhas coincidem
                                                    </span>)
                                                : (<span style={{
                                                    width: '100%',
                                                    marginTop: '.25rem',
                                                    fontSize: '80%',
                                                    color: '#dc3545'
                                                }}>
                                                    As senhas devem coincidir
                                                    </span>))
                                            : null}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="btn-change-password"></label>
                                        <button
                                            type="submit"
                                            id="btn-change-password"
                                            className="btn btn-primary btn-lg btn-block"
                                        >Alterar Senha</button>
                                    </div>
                                </div>

                            </form>

                        </div>

                    </div>

                </div>

                <footer className="my-5 pt-5 text-muted text-center text-small">
                    <p className="mb-1">© 2019 Lançando Cursos</p>
                </footer>

            </div>


        </div>
    );
}

export default User;