import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Login from './pages/Login';
import ForgotPass from './pages/ForgotPass';
import ChangePass from './pages/ChangePass';
import UserCakes from './pages/UserCakes';
import Cake from './pages/Cake';
import User from './pages/User';
import Categorias from './pages/Categorias';
import Custos from './pages/Custos';

//export default function Routes(){
const Routes = (p) => {
    return (
        <BrowserRouter>
            <Route path="/" exact render={(props) => <Login {...props} app={p} />} />
            <Route path="/EsqueciMinhaSenha" exact render={(props) => <ForgotPass {...props} app={p} />} />
            <Route path= "/AlterarSenha/:token" render={(props) => <ChangePass {...props} app={p}/>} />
            <Route path="/UserCakes" render={(props) => <UserCakes {...props} app={p} />} />
            <Route path="/Cake/:id" render={(props) => <Cake {...props} app={p} />} />
            <Route path="/User" render={(props) => <User {...props} app={p} />} />
            <Route path="/Categorias" render={(props) => <Categorias {...props} app={p} />} />
            <Route path="/Custos" render={(props) => <Custos {...props} app={p} />} />
        </BrowserRouter>
    );
}

export default Routes;