//imports do react e bibliotecas de auxilio
import React, { useEffect, useState, useRef } from 'react';
import { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import Moment from 'moment';

//imports do app
import './Cake.css';
import Menu from './Menu';
import Image from './Image';
import TableInsumos from './TableInsumos';
import api from '../services/api';

const Cake = (p) => {

    //referencia ao insumo
    const refInsumo = useRef(null);

    //constante que recebe o usuário logado
    const [user, setUser] = useState({});

    //constante que vai receber e modificar o bolo
    const [cake, setCake] = useState({
        _id: '',
        nome: '',
        tempo_preparo: '',
        categoria_id: '',
        peso: '',
        user_id: ''
    });

    // constantes que recebem as categorias do usuario e 
    // seta a categoria selecionada do bolo
    const [categorias, setCategorias] = useState([]);
    const [categSelected, setCategSelected] = useState({
        nome: '',
        preco_kg: 0
    });

    //constantes que recebem a lista de insumos do bolo
    //o modelo de insumo com todos os atributos zerados
    //e o insumo a ser salvo/modificado
    const [cakeInsumos, setCakeInsumos] = useState([]);
    const insumoModel = ({
        _id: '',
        cake_id: '',
        marca: '',
        medida: '',
        nome: '',
        preco_emb: '',
        qtd: '',
        qtd_emb: '',
        custo: ''
    });
    const [insumo, setInsumo] = useState({
        _id: '',
        cake_id: '',
        marca: '',
        medida: '',
        nome: '',
        preco_emb: '',
        qtd: '',
        qtd_emb: '',
        custo: ''
    });

    //constante que recebe a lista de insumos do usuário
    const [userInsumos, setUserInsumos] = useState([]);
    const [options] = useState([]);

    //constante que recebe a lista de custos do usuário
    const [userCustos, setUserCustos] = useState([]);

    //variaveis da projeção financeira
    var custoMaoObra = user.salario_hora * (cake.tempo_preparo / 60);
    var custosFixos = userCustos.reduce(function (sum, custo) {
        return sum + custo.valor;
    }, 0);
    custosFixos = (custosFixos/(60*user.horas_semana*4)) * cake.tempo_preparo;
    var custoInsumos = cakeInsumos.reduce(function (sum, insumo) {
        return sum + insumo.custo;
    }, 0);
    var custoTotal = custoMaoObra + custoInsumos + custosFixos;
    var precoVenda = (categSelected.preco_kg * (cake.peso / 1000));

    //buscar categorias pelo id do usuario
    async function loadCategorias(pUserId) {
        const user_id = {
            headers: {
                user: pUserId,
            }
        };

        const response = await api.get('/listCateg', user_id);
        setCategorias(response.data);
    }

    //buscar custos pelo id do usuario
    async function loadCustos(pUserId) {
        const user_id = {
            headers: {
                user: pUserId,
            }
        };

        const response = await api.get('/listCusto', user_id);
        setUserCustos(response.data);
    }

    //atualiza categoria selecionada
    async function setCategoria(cake) {
        const categ_id = {
            headers: {
                categoria: cake,
            }
        };

        const categExists = await api.get('/categ', categ_id);

        if (categExists.data !== null) {
            setCategSelected(categExists.data);
        }
    };

    //atualiza preco/kg da categoria com base no preço sugerido do bolo
    async function savePrecoCateg() {

        let categUpdated = categSelected;

        categUpdated.preco_kg = parseFloat(((custoTotal * (1 + (user.lucro / 100))) / cake.peso) * 1000).toFixed(2);

        const response = await api.post('/updateCateg', {
            categoria: categUpdated
        });

        if (response) {
            setCategSelected(response.data);
            p.app.notify('success', 'Categoria atualizada com sucesso!', 2);
        } else {
            p.app.notify('danger', 'Categoria não atualizada!', 2);
        }

    }

    //atualiza dados do bolo 
    const modifyCake = e => {
        let value = e.target.value;
        if (e.target.type === 'number') value = value.replace(',', '.');

        setCake({
            ...cake,
            [e.target.name]: value,
        }
        );

        if (e.target.name === 'categoria_id') {
            setCategoria(e.target.value);
        }
    }

    //salvar bolo
    async function onSaveCake(e) {
        e.preventDefault();

        cake.user_id = user._id;
        var path;

        if (cake._id !== '') {
            path = '/updateCake';
        } else {
            path = '/createCake';
        }

        const response = await api.post(path, {
            cake
        });

        if (path === '/createCake') p.history.push(`/Cake/${response.data._id}`);

        setCake(response.data);
        p.app.notify('success', 'Bolo salvo com sucesso!', 2);
    }

    //atualiza dados do insumo desejado
    const modifyInsumo = e => {

        let value = e.target.value;
        if (e.target.type === 'number') value = value.replace(',', '.');

        setInsumo({
            ...insumo,
            [e.target.name]: value
        });
    }

    //buscar todos insumos cadastrados por um usuário
    async function loadUserInsumos(pUser) {
        const user_id = {
            headers: {
                user: pUser,
            }
        };

        const response = await api.get('/listUserInsumos', user_id);
        setUserInsumos(response.data);
    }

    //carregar dados do usuario logado
    useEffect(() => {
        function populateOptions() {

            options.splice(0, options.length);

            for (let i = 0; i < userInsumos.length; i++) {
                let option = {
                    value: userInsumos[i]._id.nome,
                    marca: userInsumos[i]._id.marca,
                    qtd_emb: userInsumos[i]._id.qtd_emb,
                    medida: userInsumos[i]._id.medida,
                    preco_emb: userInsumos[i]._id.preco_emb,
                    label: userInsumos[i]._id.nome
                        + " (" + userInsumos[i]._id.marca + ")"
                        + " - " + userInsumos[i]._id.qtd_emb
                        + userInsumos[i]._id.medida
                        + " - R$: " + parseFloat(userInsumos[i]._id.preco_emb).toFixed(2)
                };

                options.push(option);
            }
        };

        if (userInsumos) {
            populateOptions();
        }

    }, [userInsumos, options]);

    async function handleChange(e) {

        if (e) {

            if (e.__isNew__) {

                setInsumo({
                    ...insumo,
                    nome: e.value
                });
            } else {

                insumoModel.nome = e.value;
                insumoModel.marca = e.marca;
                insumoModel.qtd_emb = e.qtd_emb;
                insumoModel.medida = e.medida;
                insumoModel.preco_emb = e.preco_emb;
                insumoModel.cake_id = cake._id;
                insumoModel.qtd = '';

                setInsumo(insumoModel);
            }

        } else {

            insumoModel.nome = '';
            setInsumo(insumoModel);
        }
    };

    const formatCreateLabel = inputValue => (
        <span style={{ color: '#3A1246' }}>Criar {inputValue}</span>
    );

    const SingleValue = props => (
        <components.SingleValue {...props}>
            {props.data.value}
        </components.SingleValue>
    );

    //buscar cakeInsumos pelo id do bolo
    async function loadCakeInsumos(pCake) {
        const cake_id = {
            headers: {
                cake: pCake,
            }
        };

        const response = await api.get('/listInsumo', cake_id);
        setCakeInsumos(response.data);
    }

    //salvar insumo
    async function onSaveInsumo(e) {
        e.preventDefault();

        insumo.custo = (insumo.preco_emb / insumo.qtd_emb) * insumo.qtd;
        insumo.cake_id = cake._id;

        var path;

        if (insumo._id !== '') {
            path = '/updateInsumo';
        } else {
            path = '/createInsumo';
        }

        const response = await api.post(path, {
            insumo
        });

        await setInsumo(insumoModel);
        if (response) {
            loadCakeInsumos(cake._id);
            p.app.notify('success', 'Insumo salvo com sucesso!', 2);
            refInsumo.current.state.value = '';
        }
    }

    //atualizar insumo
    async function updateInsumo(pInsumo) {

        setInsumo(pInsumo);
        // refInsumo.current.state.value = pInsumo.nome;
        // refInsumo.current.state.inputValue = pInsumo.nome;
        refInsumo.current.focus();
    }

    //deletar insumo    
    async function delInsumo(pInsumos, callback) {

        for (let index = 0; index < pInsumos.length; index++) {
            const pInsumo = pInsumos[index];

            const insumo_id = {
                headers: {
                    insumo: pInsumo._id,
                }
            };

            await api.get('/deleteInsumo', insumo_id);

            loadCakeInsumos(cake._id);
            p.app.notify('warning', `Insumo ${pInsumo.nome} deletado!`, 2);
        }

        callback();
    }

    //carregar dados do bolo
    useEffect(() => {

        //buscar bolo pelo id
        async function loadCake() {
            const cake_id = {
                headers: {
                    cake: p.match.params.id,
                }
            };

            const cakeExists = await api.get('/cake', cake_id);

            //setar o bolo, cakeInsumos e categoria
            if (cakeExists.data !== null) {

                setCake(cakeExists.data);
                loadCakeInsumos(cakeExists.data._id);
                setCategoria(cakeExists.data.categoria_id);
                loadUserInsumos(cakeExists.data.user_id);
            }
        }

        if (p.match.params.id !== 'new') loadCake();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.match]);

    //carregar usuario
    useEffect(() => {

        var userId;

        if (p.app.state.user) {
            setUser(p.app.state.user);
            userId = p.app.state.user._id;
        } else {
            p.history.push('/');
        };

        loadCategorias(userId);
        loadCustos(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.app.state]);

    return (

        <div>
            {/* renderiza a navbar */}
            <Menu userId={p.app.state.user._id} removeCookie={p.app.removeCookie} history={p.history} />

            <div className="main-container">

                {/* caso o bolo ja exista, carrega a imagem do mesmo */}
                {(cake._id)
                    ? <Image cakeId={cake._id} cakePic={`${api.defaults.baseURL}/public/${cake.pic}`} notify={p.app.notify} dismissNotify={p.app.dismissNotify} />
                    : null
                }

                <div className="container">

                    {/* Primeira linha que conterá a div da projeção financeira 
                    + a div dos dados do bolo */}
                    <div className="row">

                        {/* Inicio Projeção Financeira */}
                        <div className="col-md-4 order-md-2 mb-4">

                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-muted">Projeção Financeira</span>
                            </h4>

                            <ul className="list-group mb-3">
                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                    <div className="text-danger">
                                        <h6 className="my-0">Mão de Obra</h6>
                                        <small className="text-danger">{parseFloat((custoMaoObra * 100) / custoTotal).toFixed(2)}%</small>
                                    </div>
                                    <span className="text-danger">R$ {parseFloat(custoMaoObra).toFixed(2)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                    <div className="text-danger">
                                        <h6 className="my-0">Custos Fixos</h6>
                                        <small className="text-danger">{parseFloat((custosFixos * 100) / custoTotal).toFixed(2)}%</small>
                                    </div>
                                    <span className="text-danger">R$ {parseFloat(custosFixos).toFixed(2)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                    <div className="text-danger">
                                        <h6 className="my-0">Insumos</h6>
                                        <small className="text-danger">{parseFloat((custoInsumos * 100) / custoTotal).toFixed(2)}%</small>
                                    </div>
                                    <span className="text-danger">R$ {parseFloat(custoInsumos).toFixed(2)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between">
                                    <div className="text-danger">
                                        <span>Total (CUSTOS)</span>
                                    </div>
                                    <strong className="text-danger">R$ {parseFloat(custoTotal).toFixed(2)}</strong>
                                </li>
                                <li className="list-group-item d-flex justify-content-between bg-light">
                                    <div className="text-success">
                                        <h6 className="my-0">Faturamento Bruto</h6>
                                    </div>
                                    <span className="text-success">R$ {parseFloat(precoVenda).toFixed(2)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between bg-light">
                                    <div className="text-success">
                                        <h6 className="my-0">Margem Bruta</h6>
                                        <small>{parseFloat(((precoVenda - custoTotal) / precoVenda) * 100).toFixed(2)}%</small>
                                    </div>
                                    <span className="text-success">R$ {parseFloat(precoVenda - custoTotal).toFixed(2)}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between bg-success">
                                    <h5 className="text-white">Preço Sugerido</h5>
                                    <h5 className="text-white"><strong>R$ {parseFloat(custoTotal * (1 + (user.lucro / 100))).toFixed(2)}</strong></h5>
                                </li>

                            </ul>

                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary btn-lg btn-block btn-save-categ"
                                    onClick={() => savePrecoCateg()}
                                >
                                    Atualizar preço/kg da categoria com base no bolo
                            </button>
                            </div>

                        </div>
                        {/* Fim Projeção Financeira */}

                        {/* Inicio Dados do Bolo */}
                        <div className="col-md-8 order-md-1">

                            <form className="needs-validation" onSubmit={onSaveCake}>

                                <div className="row">
                                    <div className="col-md-8 mb-3">
                                        <h4 className="mb-1">Ficha do Bolo</h4>
                                        <small className="text-muted">Última Atualização: {Moment(cake.updatedAt).format('DD/MM/YYYY HH:mm')}</small>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col mb-3">
                                        <label htmlFor="nome">Nome</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="nome"
                                            value={cake.nome}
                                            onChange={modifyCake}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col mb-3">
                                        <label htmlFor="categoria_id">Categoria</label>
                                        <select
                                            className="custom-select d-block w-100"
                                            name="categoria_id"
                                            value={cake.categoria_id}
                                            onChange={modifyCake}
                                            required={true}
                                        >
                                            <option value="">Selecionar categoria...</option>
                                            {categorias.map(categoria => (
                                                <option key={categoria._id} value={categoria._id}> {categoria.nome} </option>
                                            ))};
                                        </select>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="preco_kg">Preço/Kg</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">R$</span>
                                            </div>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="preco_kg"
                                                readOnly={true}
                                                placeholder={categSelected.preco_kg}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="peso">Peso Unitário</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                pattern="[0-9]+([\,|\.][0-9]+)?"
                                                step="0.01"
                                                className="form-control"
                                                name="peso"
                                                value={cake.peso}
                                                onChange={modifyCake}
                                                required={true}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">g</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="tempo_preparo">Tempo Preparo</label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                pattern="[0-9]+([\,|\.][0-9]+)?"
                                                step="0.01"
                                                className="form-control"
                                                name="tempo_preparo"
                                                value={cake.tempo_preparo}
                                                onChange={modifyCake}
                                                required={true}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">min</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="tempoPcusto_unitarioreparo">Custo Unitário</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">R$</span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="custo_unitario"
                                                readOnly={true}
                                                value={parseFloat(custoTotal).toFixed(2)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col mb-3">
                                        <label htmlFor="preco_total">Preço de Venda</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text preco-total-span">R$</span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control preco-total-input"
                                                name="preco_total"
                                                readOnly={true}
                                                value={parseFloat((categSelected.preco_kg * (cake.peso / 1000)).toFixed(2))}
                                            />
                                        </div>
                                    </div>
                                    <div className="col mb-3">
                                        <label htmlFor="btn-add-bolo"></label>
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-lg btn-block btn-add-bolo"
                                        >
                                            Salvar Bolo
                                </button>
                                    </div>
                                </div>

                            </form>

                        </div>
                        {/* Fim Dados do Bolo */}

                    </div>
                    {/* Fim da primeira linha */}

                    <hr className="mb-4" />

                    {/* segunda linha com os dados e tabela dos cakeInsumos do bolo */}
                    <div className="row">

                        {/* renderiza apenas quando o bolo já existir no banco */}
                        {(cake._id)

                            // Inicio do cadastro de Insumos
                            ? (<div className="col-md-12 mb-4">
                                <div className="row">
                                    <div className="col mb-3">
                                        <h4 className="mb-1">Insumos</h4>
                                    </div>
                                </div>

                                <form
                                    className="needs-validation"
                                    noValidate=""
                                    onSubmit={onSaveInsumo}
                                >

                                    <div className="row">
                                        <div className="col mb-3">
                                            <label htmlFor="select">Nome</label>
                                            {(!insumo._id) ?
                                                (<CreatableSelect
                                                    isClearable
                                                    ref={refInsumo}
                                                    name="nomeInsumo"
                                                    id="idInsumo"
                                                    options={options}
                                                    onChange={handleChange}
                                                    placeholder="Digite o nome do insumo..."
                                                    formatCreateLabel={formatCreateLabel}
                                                    components={{ SingleValue }}
                                                />)
                                                : (<input
                                                    ref={refInsumo}
                                                    type="text"
                                                    className="form-control"
                                                    name="nome"
                                                    value={insumo.nome}
                                                    onChange={modifyInsumo}
                                                    required={true}
                                                />)}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-8 mb-3">
                                            <label htmlFor="validade">Marca</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="marca"
                                                value={insumo.marca}
                                                onChange={modifyInsumo}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validade">Preço Embalagem</label>
                                            <input
                                                type="number"
                                                pattern="[0-9]+([\,|\.][0-9]+)?"
                                                step="0.01"
                                                className="form-control"
                                                name="preco_emb"
                                                value={insumo.preco_emb}
                                                onChange={modifyInsumo}
                                                required={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validade">Qtd Embalagem</label>
                                            <input
                                                type="number"
                                                pattern="[0-9]+([\,|\.][0-9]+)?"
                                                step="0.01"
                                                className="form-control"
                                                name="qtd_emb"
                                                value={insumo.qtd_emb}
                                                onChange={modifyInsumo}
                                                required={true}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validade">Medida Embalagem</label>
                                            <select
                                                className="custom-select d-block w-100"
                                                name="medida"
                                                value={insumo.medida}
                                                onChange={modifyInsumo}
                                                required={true}
                                            >
                                                <option value="">Escolha...</option>
                                                <option value="(g)">Grama (g)</option>
                                                <option value="(un)">Unidade (un)</option>
                                                <option value="(ml)">Mililitro (ml)</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validade">Qtd Utilizada</label>
                                            <input
                                                type="number"
                                                pattern="[0-9]+([\,|\.][0-9]+)?"
                                                step="0.01"
                                                className="form-control"
                                                name="qtd"
                                                value={insumo.qtd}
                                                onChange={modifyInsumo}
                                                required={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col mb-3">
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-lg btn-block btn-add-insumo"
                                            >
                                                Salvar Insumo
                                            </button>
                                        </div>
                                    </div>

                                </form>
                                {/* Fim do cadastro de Insumos */}

                                {/* Inicio da tabela de cakeInsumos do bolo */}
                                <div className="row">
                                    <div className="col mb-3">

                                        <TableInsumos
                                            rows={cakeInsumos}
                                            custoInsumos={custoInsumos}
                                            updateInsumo={updateInsumo}
                                            delInsumo={delInsumo}
                                        />

                                    </div>
                                </div>
                                {/* Fim da tabela de cakeInsumos do bolo */}

                            </div>)
                            // fim do if de renderização

                            // não renderiza nada caso o bolo ainda não exista no banco
                            : null}

                    </div>

                </div>

            </div>

            <footer className="footer my-5 pt-5 text-muted text-center text-small">
                <p className="mb-1">© 2019 Lançando Cursos</p>
            </footer>

        </div>
    );
}

export default Cake;