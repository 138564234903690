import React from 'react';

import './Menu.css';

import logo from '../assets/logo.svg';
import { GiChecklist, GiCakeSlice, GiExitDoor, GiPayMoney } from "react-icons/gi";
import { IoIosSettings } from "react-icons/io";

class Main extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            userPath: "",
            userCakePath: "",
            custoPath: "",
            categPath: ""
        }

        this.logout = this.logout.bind(this);
        this.listCakes = this.listCakes.bind(this);
        this.goToSettings = this.goToSettings.bind(this);
    }

    componentDidMount(){

        const pathname = this.props.history.location.pathname;

        if (pathname.includes('/UserCakes')) {
            this.setState({
                userCakePath: "active"
            });
        } else if (pathname.includes('/User')) {
            this.setState({
                userPath: "active"
            });
        } else if (pathname.includes('/Categorias')) {
            this.setState({
                categPath: "active"
            });
        } else if (pathname.includes('/Custos')) {
            this.setState({
                custoPath: "active"
            });
        }
    }

    logout() {

        this.props.removeCookie('user');
        this.props.history.push('/');
        window.location.reload();
    }

    listCakes() {
        this.props.history.push('/UserCakes');
    }

    listCategorias() {
        this.props.history.push('/Categorias');
    }

    listCustos() {
        this.props.history.push('/Custos');
    }

    goToSettings() {
        this.props.history.push('/User');
    }

    render() {
        return (
            <div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-light">
                    <a className="navbar-brand" href="/">
                        <img src={logo} alt="Cakey" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#textoNavbar" aria-controls="textoNavbar" aria-expanded="false" aria-label="Alterna navegação">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="textoNavbar">
                        <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <div className={`icons ${this.state.userPath}`}>
                                <button onClick={() => {this.goToSettings()}}>
                                    <IoIosSettings className="menu-icon icon-settings"/> Preferencias
                                </button>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className={`icons ${this.state.custoPath}`}>
                                <button onClick={() => {this.listCustos()}}>
                                    <GiPayMoney className="menu-icon"/> Gerenciar Custos
                                </button>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className={`icons ${this.state.categPath}`}>
                                <button onClick={() => {this.listCategorias()}}>
                                    <GiChecklist className="menu-icon"/> Gerenciar Categorias
                                </button>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className={`icons ${this.state.userCakePath}`}>
                                <button onClick={() => {this.listCakes()}}>
                                    <GiCakeSlice className="menu-icon"/> Gerenciar Bolos
                                </button>
                            </div>
                        </li>
                        </ul>
                        <span className="navbar-text">
                            <div className="icons">
                                <button onClick={() => {this.logout()}}>
                                    <GiExitDoor className="menu-icon"/> Logout
                                </button>
                            </div>
                        </span>
                    </div>
                </nav>                          
            </div>
        );
    }
}


export default Main;