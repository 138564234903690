import React, { useEffect, useState } from 'react';
import './Login.css';

import api from '../services/api';

import logo from '../assets/logo.svg';

// export default function Login({ history }) {
const Login = (p) => {
    const [user, setUser] = useState({
        email: '',
        password: ''
    });
    const [msgErro, setMsgErro] = useState({ error: '' });

    useEffect(() => {

        if (p.app.state.user) {
            p.history.push('/UserCakes');
        }

    }, [p]);

    const updateUser = e => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault();

        api.post('/login', {
            email: user.email,
            password: user.password
        }).then((response) => {
            if (response.status === 200) {
                const { _id, nome } = response.data;

                if (_id !== null) {

                    p.app.setCookie('user', response.data);

                    if (nome === '') {
                        p.history.push(`/User/${_id}`);
                    } else {
                        p.history.push(`/UserCakes/${_id}`);
                    }
                }
            } else {
                const error = new Error(response.error);
                throw error;
            }
        }).catch((err) => {
            console.error(err);
            setMsgErro(err.response.data);
        });
    }

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit}>
                <img src={logo} alt='Cakey' />
                <input
                    placeholder="Digite seu e-mail"
                    type="email"
                    name="email"
                    value={user.email}
                    onChange={updateUser}
                />
                <input
                    placeholder="Digite sua senha"
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={updateUser}
                />

                <span style={{
                    width: '100%',
                    marginTop: '.25rem',
                    fontSize: '80%',
                    color: '#dc3545'
                }}>
                    {msgErro.error}
                </span>

                <button type="submit">Entrar</button>
                <span style={{width: '100%', marginTop: '0.25rem', fontSize: '80%'}}>
                    <a href="/EsqueciMinhaSenha">Esqueceu sua senha?</a>
                </span>
            </form>
        </div>
    );
}

export default Login;