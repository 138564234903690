import React, { useEffect, useState, useRef } from 'react';

import Menu from './Menu';
import TableCategorias from './TableCategorias';

// import './Categoria.css';

import api from '../services/api';

const Categoria = (p) => {

    const refCateg = useRef(null);

    const [user, setUser] = useState({
        _id: ''
    });
    const [categorias, setCategorias] = useState([]);
    const categoriaModel = ({
        nome: '',
        preco_kg: 0
    });
    const [categoria, setCategoria] = useState({
        nome: '',
        preco_kg: 0
    });
    const [updatingCateg, setUpdatingCateg] = useState(false);

    //atualiza dados da categoria
    const modifyCategoria = e => {
        let value = e.target.value;
        if (e.target.type === 'number') value = value.replace(',', '.');

        setCategoria({
            ...categoria,
            [e.target.name]: value,
        }
        );
    }

    //atualizar categoria
    async function updateCategoria(pCategoria) {
        setCategoria(pCategoria);
        refCateg.current.focus();
        setUpdatingCateg(true);
    }

    //deletar categoria
    async function delCategoria(pCategorias, callback) {

        for (let index = 0; index < pCategorias.length; index++) {
            const pCategoria = pCategorias[index];

            const categId = {
                headers: {
                    categoria: pCategoria._id,
                }
            };

            await api.get('/deleteCateg', categId);

            loadCategorias(user._id);
            p.app.notify('warning', `Categoria ${pCategoria.nome} deletada!`, 2);
        }

        if (updatingCateg) {
            cancelUpdating();
        }

        callback();
    }

    function cancelUpdating() {
        setUpdatingCateg(false);
        setCategoria(categoriaModel);
    }

    //buscar categorias pelo id do usuario
    async function loadCategorias(pUserId) {
        const user_id = {
            headers: {
                user: pUserId,
            }
        };

        const response = await api.get('/listCateg', user_id);
        setCategorias(response.data);
    }

    //cadastrar Categoria
    async function handleSaveCategoria(e) {
        e.preventDefault();

        categoria.user_id = user._id;
        var path;

        if (categoria.hasOwnProperty('_id')) {
            path = '/updateCateg';
        } else {
            path = '/createCateg';
        }

        const response = await api.post(path, {
            categoria
        });

        if (response) {
            loadCategorias(user._id);
            p.app.notify('success', 'Categoria salva com sucesso!', 2);
            cancelUpdating();
        } else {
            p.app.notify('danger', 'Categoria não salva!', 2);
        }

    }

    //carregar usuario
    useEffect(() => {

        if (p.app.state.user) {
            setUser(p.app.state.user);
        } else {
            p.history.push('/');
        };
    }, [p]);

    useEffect(() => {

        if (user._id !== '') loadCategorias(user._id);

    }, [user._id]);

    return (
        <div>
            {(p.app.state.user)
                ? <Menu userId={p.app.state.user._id} removeCookie={p.app.removeCookie} history={p.history} />
                : null
            }

            <div className="main-container">

                <div className="container">

                    <div className="row">

                        <div className="col-md-8 mb-4">
                            <h4 name="formCategoria" className="mb-1">Categorias</h4>
                        </div>

                        {/* <!-- Adicionar nova categoria --> */}
                        <div className="col-md-12 mb-4">
                            <form
                                className="needs-validation"
                                noValidate=""
                                onSubmit={handleSaveCategoria}
                            >
                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label htmlFor="nome">Nome</label>
                                        <input
                                            ref={refCateg}
                                            type="text"
                                            className="form-control"
                                            name="nome"
                                            value={categoria.nome}
                                            onChange={modifyCategoria}
                                            required={true}
                                        />
                                        <span
                                            hidden={updatingCateg === false}
                                            style={{
                                                width: '100%',
                                                marginTop: '.25rem',
                                                fontSize: '80%',
                                                color: '#3A1246'
                                            }}>
                                            Atualizando categoria {categoria.nome}... &nbsp;&nbsp;
                                            <span
                                                onClick={cancelUpdating}
                                                style={{
                                                    color: '#3587dc',
                                                    cursor: 'pointer'
                                                }}>Cancelar</span>
                                        </span>
                                    </div>
                                    <div className="col-md mb-3">
                                        <label htmlFor="preco_kg">Preço por Kg</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">R$</span>
                                            </div>
                                            <input
                                                type="number"
                                                pattern="[0-9]+([\,|\.][0-9]+)?"
                                                step="0.01"
                                                className="form-control"
                                                name="preco_kg"
                                                value={categoria.preco_kg}
                                                onChange={modifyCategoria}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md mb-3">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-lg btn-block btn-save-categ"
                                        >Salvar Categoria</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/* <!-- Tabela de Categorias --> */}
                        <TableCategorias
                            rows={categorias}
                            updateCategoria={updateCategoria}
                            delCategoria={delCategoria}
                        />


                    </div>

                </div>

                <footer className="my-5 pt-5 text-muted text-center text-small">
                    <p className="mb-1">© 2019 Lançando Cursos</p>
                </footer>

            </div>


        </div>
    );
}

export default Categoria;