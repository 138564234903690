import React from 'react';

import api from '../services/api';
import './Image.css';

const FormData = require('form-data');

class Main extends React.Component {

    constructor(props) {
        super(props);

        this.handleUploadImage = this.handleUploadImage.bind(this);

        this.state = {
            cakePic: ''
        }
    }

    componentDidMount() {
        this.setPic(`${this.props.cakePic}`);
    }

    setPic(e) {
        this.setState({
            cakePic: e
        });
    }

    handleUploadImage(ev) {
        ev.preventDefault();

        let file = this.uploadInput.files[0];
        let dismissNotify = this.props.dismissNotify;

        const data = new FormData();
        data.append('image', file, this.props.cakeId);

        this.props.notify('warning', 'Atualizando imagem do bolo, aguarde...', 0, false);

        api.post('/uploadImage', data)
            .then((response) => {
                this.setPic('');
                this.setPic(`${api.defaults.baseURL}/public/${response.data.file}`);
                this.props.notify('success', 'Imagem atualizada!', 0, false);
                setTimeout(function () {
                    dismissNotify(1, 'tc', undefined);
                    dismissNotify(0, 'tc', undefined);
                }, 2000);

            }).catch((error) => {
                this.props.notify('danger', 'Não foi possivel atualizar a imagem', 0, false);
                setTimeout(function () {
                    dismissNotify(1, 'tc', undefined);
                    dismissNotify(0, 'tc', undefined);
                }, 2000);
            });
    }

    addDefaultPic(e) {
        e.target.src = `${api.defaults.baseURL}/public/cake.png`;
    }

    render() {
        return (
            <div className="btn btn-default btn-file">
                <img
                    ref="imgCake"
                    className="cake-pic"
                    src={this.state.cakePic}
                    onError={(e) => { this.addDefaultPic(e) }}
                    alt="cake"
                />
                {(this.props.cakeId)
                    ? (
                        <input
                            name="image"
                            ref={(ref) => { this.uploadInput = ref; }}
                            type="file"
                            onChange={this.handleUploadImage}
                        />)
                    : null
                }
            </div>
        );
    }
}

export default Main;