import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './UserCakes.css';
import Menu from './Menu';

import api from '../services/api';

import { IoIosAddCircleOutline, IoMdRemoveCircle } from "react-icons/io";
import { FaPencilAlt } from "react-icons/fa";

const UserCakes = (p) => {

    const user = p.app.state.user;
    const cakes = p.app.state.cakes;
    const [busca, setBusca] = useState('');

    function modifyBusca(e) {
        setBusca(e.target.value);
    }

    //carregar bolos
    async function loadCakes() {

        const userHeaders = {
            headers: {
                userid: user._id,
                datecake: p.app.state.maxDateCake
            }
        };

        const response = await api.get('/listCake', userHeaders);
        if (response.status === 200 && response.data.length > 0) p.app.setCakes(response.data);
    }

    //carregar usuario
    useEffect(() => {

        if (user) {
            loadCakes();
        } else {
            p.history.push('/');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //deletar insumo    
    function delCake(pCake) {
        const cakeHeaders = {
            headers: {
                cake: pCake,
            }
        };

        api.get('/deleteCake', cakeHeaders).then((res) => {
            if (res.status === 200) {
                p.app.removeCake(pCake);
                p.app.notify('warning', 'Bolo deletado!', 2);
            } else {
                p.app.notify('danger', 'Não foi possivel deletar o bolo no momento', 2);
            }
        });

    }

    //imagem default caso bolo não contenha foto
    function addDefaultPic(e) {
        e.target.src = `${api.defaults.baseURL}/public/cake.png`;
    }

    return (
        <div>
            {/* renderiza a navbar */}
            {(user)
                ? <Menu userId={user._id} removeCookie={p.app.removeCookie} history={p.history} />
                : null
            }

            <div className="main-container">

                {/* renderiza cards dos bolos, caso usuário possua */}
                <div className="album py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="nome">Buscar Bolo</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="nome"
                                    value={busca}
                                    onChange={modifyBusca}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            {cakes.length > 0 ? (
                                cakes.filter(function(cake) {
                                    return cake.nome.toLowerCase().includes(busca.toLowerCase()) || busca === ''
                                }).map(cake => (
                                    <div key={cake._id} className="col-md-4">
                                        <div className="card mb-4 shadow-sm">
                                            <div
                                                className="bd-placeholder-img card-img-top"
                                                width="100%"
                                                height="300"
                                                role="img">
                                                <Link to={`/Cake/${cake._id}`}>
                                                    <img
                                                        src={`${api.defaults.baseURL}/public/${cake.pic}`}
                                                        onError={(e) => { addDefaultPic(e) }}
                                                        alt="cake"
                                                    />
                                                </Link>
                                            </div>
                                            <div className="card-body">
                                                <div className="cake-name">
                                                    <h2>{cake.nome}</h2>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="btn-group">
                                                        <Link to={`/Cake/${cake._id}`}>
                                                            <FaPencilAlt className="update-icon" />
                                                        </Link>
                                                        <IoMdRemoveCircle
                                                            className="del-icon"
                                                            onClick={() => { if (window.confirm('Quer mesmo deletar esse bolo?')) delCake(cake._id) }}
                                                        />
                                                    </div>
                                                    <small className="text-muted">Tempo preparo: {cake.tempo_preparo} mins</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))) : null}
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <div className="buttons">
                                        <button>
                                            <Link to="/Cake/new">
                                                <IoIosAddCircleOutline className="add-img" />
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <footer className="footer my-5 pt-5 text-muted text-center text-small">
                <p className="mb-1">© 2019 Lançando Cursos</p>
            </footer>

        </div>
    );
}

export default UserCakes;